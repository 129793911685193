@font-face {
    font-family: 'LibreBaskerville';
    src:
        local('LibreBaskerville'),
        url('./fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf')
            format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Heebo';
    src:
            local('Heebo'),
            url('./fonts/Heebo/static/Heebo-Regular.ttf')
            format('truetype');
    font-weight: bold;
}

html {
    overflow-y: scroll;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    font-weight: normal;
}
body {
    flex: 1 0 auto;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 100;
}
html,
body {
    margin: 0;
}

*,
*::before,
*::after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a,
button {
    cursor: pointer;
    color: currentColor;
    text-decoration: none;
}

h1,
h2 {
    margin: 0;
    line-height: 1.05;
    font-family: LibreBaskerville, serif;
    font-weight: 400;
}

h2 {
    line-height: 1.3;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-weight: normal;
    margin: 0;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

img {
    display: block;
}

button,
input {
    appearance: none;
    border: none;
    outline: none;
    padding: 0;
    background: none;
}

body {
    margin: 0;
    padding: 0;
    font-family:
        'Heebo',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: Heebo, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
