.panel.projects {
    background: #f8e9e6;

    .project-wrapper {
        width: 80%;
    }

    .card__image > img {
        width: 100%;
    }

    h1 {
        margin-bottom: 20px;
    }

    .project-container {
        display: flex;
        gap: 30px;

        .panel.projects {
            block-size: calc(100vh - 80px);
        }

        .card__overlay-content {
            height: 100%;
        }

        .project {
            width: 50%;
        }

        .grid {
            width: 50%;
        }
    }

    @media all and (max-width: 480px) {
        .project-container {
            flex-direction: column;

            .project {
                width: 100%;
            }

            .grid {
                width: 100%;
            }
        }
    }
}

// PROJECTS 2
.spacer {
    width: 100%;
    height: 100vh;
    background: #ddd;
}

.gallery h1 {
    color: #c07474;
    font-size: clamp(2em, 4vw, 6em);
}

.gallery .description {
    font-size: clamp(1.4em, 2.5vw, 3.5em);
    line-height: 1.4;
}

.gallery {
    display: flex;
    background: #f3d3b0;
}

.image {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.left {
    width: 50%;
}

.right {
    height: 100vh;
    /* outline:1px solid purple; */
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //position: fixed;
    //left: auto !important;
}

.desktopContent {
    margin: auto;
    width: 80%;
}

.desktopContentSection {
    min-height: 100vh;
    /* outline:1px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.icon {
    height: 25px;
}

.link {
    border-radius: 50px;
    padding: 10px 20px;
    border: 3px solid #c07474;
    background: transparent;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 9px;
    justify-content: center;
}

.desktopPhotos {
    width: 40vw;
    height: 40vw;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}

.desktopPhoto {
    position: absolute;
    width: 100%;
    height: 100%;
}

.red {
    background: crimson;
}

.green {
    background: MediumSeaGreen;
}

.blue {
    background: dodgerblue;
}

.pink {
    background: deepPink;
}

/* small screen / mobile layout */
.mobileContent {
    display: none;
    width: 80vw;
    margin: 2rem 0;
}

.mobileContentContainer {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin: 1rem 0 4rem 0;

    .link {
        margin: 0 auto;
    }
}

.mobilePhoto {
    width: 80vw;
    height: 80vw;
    border-radius: 6vw;
}

/* defines styles for screens up to 599px wide */
@media screen and (max-width: 599px) {
    .left {
        display: none;
    }

    .right {
        height: auto;
        width: 100%;
        align-items: center;
    }

    .desktopPhotos {
        display: none;
    }

    .mobileContent {
        display: block;
    }
}
