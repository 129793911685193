.panel.brainwave-boulevard {
    background: #e0dcdc;

    img {
        height: 120px;
        width: 120px;
    }

    h1 {
        margin-bottom: 20px;
    }

    .skills {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        height: 100vh;

        //display: flex;
        //width: 100%;
        //justify-content: start;
    }

    .skill {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;

        &:first-child {
            background: #c5d7c9;
        }

        &:nth-child(2) {
            background: #cdd5e0;
        }

        &:nth-child(3) {
            background: #f8e9e6;
        }

        &:nth-child(4) {
            background: #f3d3b0;
        }

        &:nth-child(5) {
            background: #d8c0c0;
        }

        &:nth-child(6) {
            background: #d7cec5;
        }
    }

    .skill-description {
        width: 60%;
        text-align: center;
        text-transform: capitalize;
    }

    ul {
        width: auto;
        list-style: none;
        padding: 2px;
    }

    @media all and (max-width: 480px) {
        .skills {
            flex-direction: column;
        }
    }
}
