.butt {
    width: 100px;
    height: 40px;
}

.item {
    width: 100%;
    height: calc(100vh - 80px);
    border-top: 0.5px solid #e1c4be;

    &.active {
        .titleWrapper {
            border-right: none;
            background-color: #e1c4be;
            opacity: 0.9;
        }
        .publication__container {
            width: 700px;
            opacity: 1;
            pointer-events: none;
        }

        .publication__text {
            visibility: visible;
            opacity: 1;
        }

        .pdf {
            display: flex;
            opacity: 1;
        }
    }
}

.itemWrapper {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 80px);
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: auto;
    height: auto;
    border-left: 0.5px solid #f8f8f8;
    border-right: 0.5px solid #f8f8f8;
    cursor: pointer;
    gap: 5px;
    padding: 10px;
    transition: all 0.3s ease;
    background-color: #f8e9e6;

    &:hover {
        background: #e1c4be;
        opacity: 0.9;
        box-shadow: -2px 20px 20px rgba(0, 0, 0, 0.4);
        transform: scale(1.03, 1.01);
    }

    .description {
        display: flex;
        width: 100%;
        height: 75%;
        padding: 40px 0 0 0;
        gap: 5px;
        justify-content: center;

        h1 {
            writing-mode: vertical-rl;
        }

        h3 {
            writing-mode: vertical-rl;
        }
    }

    .journalWrapper {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 130px;
        word-break: break-word;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        border-bottom: 6px double black;
        padding: 10px 0;
        border-top: 6px double black;
        height: 23%;
        background: #f8f8f8;
        color: black;

        svg {
            height: 65px;
            width: 35px;
            color: #e1c4be;
        }
    }
}

.title {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    text-align: start;
}

.publication__container {
    width: 0;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.5s ease;
    border-top: 4px solid #e1c4be;
    border-bottom: 4px solid #e1c4be;
    background-color: #f8f8f8;
    pointer-events: none;

    .publication__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        margin: 50px 20px 20px;
        text-align: center;
        justify-content: space-around;
        height: 100%;
        opacity: 0;
        transition: all 0.5s ease;
    }

    .description {
        width: 100%;
    }

    .authors {
        color: black;
        display: inline;
        list-style-type: none;
        margin-bottom: 0;
        padding-right: 1em;

        font-size: 0.85em;
        font-variant: small-caps;
        letter-spacing: 1px;

        &:not(:last-child):after {
            content: '\25cf';
            font-style: normal;
            position: relative;
            color: rgba(white, 0.25);
            right: -0.6em;
        }
    }
}

.summary {
    padding: 10px;
    border-right: 1px solid black;
}

.imageWrapper {
    height: 60%;
    opacity: 0.9;

    img {
        width: 100%;
        height: 100%;
    }
}

.readMe {
    background: #e1c4be;
    padding: 5px;
    font-weight: 600;
    pointer-events: visible;
}

@media all and (max-width: 480px) {
    .item {
        width: 100%;
        height: 100%;
        flex-direction: column;

        &.active {
            height: auto;
            width: 100%;

            .publication__container {
                height: 100%;
                width: 100%;
                opacity: 1;
                border-bottom: 1px solid black;
            }

            .publication__text {
                visibility: visible;
                height: auto;
            }
        }
    }

    .itemWrapper {
        width: 100%;
        height: 100%;
        flex-direction: column;
    }

    .publication__text {
        height: 0;
    }

    .description {
        height: 80%;
        display: flex;

        h1 {
            writing-mode: unset;
        }

        h3 {
            writing-mode: unset;
        }
    }

    .title {
        writing-mode: unset;
        text-orientation: mixed;
        text-align: start;
    }

    .titleWrapper {
        width: 100%;
        flex-direction: column;
        padding: 15px;
        align-items: start;
        height: 100%;

        border-left: unset;
        border-right: unset;
        border-bottom: 1px solid black;

        .journalWrapper {
            height: 100%;
            width: 100%;
        }

        .description {
            height: 100%;
            width: 100%;
            flex-direction: column-reverse;
            font-size: 10px;
            padding: 15px 0 0 0;
        }
    }

    .title {
        height: 100%;
    }

    .year {
        height: 100%;
    }

    .publication__container {
        height: 0;
        border-top: unset;
        border-bottom: unset;
        border-bottom: unset;
        border-right: unset;
        margin-left: unset;
    }
}
