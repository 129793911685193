.contact {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(162, 189, 191, 0.51);

    a {
        text-decoration: underline;
        text-decoration-color: #f8e9e6;
    }
}

.emails {
    display: flex;
    gap: 3px;
}

.postDoc {
    text-align: center;
}

.contact-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media all and (max-width: 480px) {
        flex-direction: column;
        gap: 15px;
    }

    .carmen {
        width: 45%;
        display: block;
        max-width: 400px;
    }

    .details {
        width: 50%;
        gap: 12px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;

        @media all and (max-width: 480px) {
            width: 100%;
            gap: 10px;
        }
    }
}

.social-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 8px;
}

$social-button-size: 47px;
$social-button-icon-size: 0.6;
$social-button-background: #f8e9e6;
$social-button-active-color: #f8e9e6;
$social-button-transition-time: 0.2s;

$social-button-colors: (
    'mail': #0072c6,
    'linkedin': #0077b5,
    'twitter': #55acee,
    'orcid': #a6ce39,
);

.social-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: $social-button-size;
    height: $social-button-size;
    text-decoration: none;
    border-radius: 100%;
    background: $social-button-background;
    text-align: center;

    &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: 50%;
        display: block;
        width: 0;
        height: 0;
        border-radius: 100%;
        transition: $social-button-transition-time;
    }

    &:focus,
    &:hover {
        color: $social-button-active-color;

        &::after {
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            margin-left: calc(-50% - 1px);
        }
    }

    i,
    svg {
        position: relative;
        z-index: 1;
        transition: $social-button-transition-time;
    }

    i {
        font-size: $social-button-size * $social-button-icon-size;
    }

    svg {
        height: percentage($social-button-icon-size);
        width: percentage($social-button-icon-size);
    }

    @each $name, $color in $social-button-colors {
        &--#{$name} {
            color: $color;

            &::after {
                background: $color;
            }
        }
    }
}
