.wrapper {
    height: 100vh !important;
}

.video-content {
    font-weight: bold;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: start;

    // Cut-out text effect
    background: #e0dcdc;
    color: #222222;
    mix-blend-mode: lighten;

    h1 {
        font-size: 10vw;
    }
}

.video-content {
    position: absolute;
    padding: 1em;
    width: 100vw;
    height: 100vh;
    top: 0;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: start;

    // Cut-out text effect
    background: #e0dcdc;
    color: #222222;
    mix-blend-mode: lighten;

    h1 {
        font-size: calc(100px + 5vmin);
        margin-left: 20px;
    }

    @media all and (max-width: 480px) {
        overflow: hidden;

        h1 {
            font-size: 10vw;
            z-index: 9999;
        }
    }
}

.video-wrapper {
    background: yellow;
    height: 100%;
    width: 100%;
}

video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0.7;
}
