.brainAnimation-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.brain {
    width: 100%;
    height: 100%;
    position: absolute;
}

.brain__svg {
    height: 100%;
    width: 100%;
    transform: rotate(-90deg) scale(1.3);
}

#overlay {
    z-index: 1;
}

#underlay {
    z-index: -1;
}

.st0 {
    animation: neuronLineDraw 3.5s ease infinite alternate;
    fill: none;
    stroke: #03a403;
    stroke-miterlimit: 10;
}
.st1 {
    fill: rgba(black, 0.7);
    stroke-miterlimit: 10;
    animation: neuronLineDraw 3.5s ease infinite alternate;
}

.st2 {
    fill: none;
    stroke: rgba(black, 0.6);
    stroke-miterlimit: 10;
    animation: neuronLineDraw 3s ease infinite alternate;
}
.st3 {
    fill: darkgreen;
    stroke: #03a403;
    stroke-width: 0.3;
    stroke-miterlimit: 10;
    animation:
        neuronLineDraw 4s ease infinite alternate,
        neuronLineFill 4s ease infinite alternate 5.2s;
}

.st4 {
    fill: rgba(black, 0.7);
    animation: neuronLineDraw 2s ease infinite alternate;
}

.st5 {
    fill: rgba(#d8c0c0, 0.7);
    stroke: #03a403;
    stroke-miterlimit: 10;
    stroke-width: 1;
    animation:
        neuronLineDraw 2s ease infinite alternate,
        neuronLineFill 4s ease infinite alternate 5.2s;
}

.st6 {
    fill: darkgreen;
    stroke: #03a403;
    stroke-width: 0.5;
    stroke-miterlimit: 10;
    animation:
        neuronLineDraw 4s ease infinite alternate,
        neuronLineFill 4s ease infinite alternate 5.2s;
}

/* Keyframes */
@keyframes neuronLineDraw {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes neuronLineFill {
    100% {
        fill: rgba(#d8c0c0, 0.8);
    }
}
