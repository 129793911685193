.navbar {
    background-color: #f8e9e6;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    z-index: 20;
    width: 100%;
}

.visible {
    top: 0;
    transition: top 0.4s ease-out;
}

.hidden {
    top: -80px;
    transition: top 0.4s ease-out;
}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
}

.main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.nav-logo {
    color: #f5b921;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
}

.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
}

.nav-links {
    text-transform: lowercase;
    color: #090505e0;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
}

.nav-item {
    line-height: 15px;
    margin-right: 1rem;

    h3 {
        text-transform: uppercase;
        font-size: 20px;
    }
}

.nav-item:after {
    content: '';
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition:
        width 0.7s ease,
        background-color 0.5s ease;
}

.nav-item:hover:after {
    width: 100%;
    background: #f5b921;
}

.nav-item.active {
    color: #f5b921;
    border: 1px solid #f5b921;
}

.nav-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #fff;
        position: absolute;
        height: calc(100vh - 80px);
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
        align-items: center;
        justify-content: center;
    }

    .nav-menu.active {
        background: #e1c4be;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        overflow: hidden;
    }
    .nav-item .active {
        color: white;
        border: none;
    }
    .nav-links {
        padding: 1.5rem;
        width: 100%;
        display: table;
    }

    @mixin btn() {
        display: block;
        cursor: pointer;
        position: absolute;
        width: 60px;
        height: 60px;
        top: 10px;
        right: 0;
        transition-duration: 0.5s;
    }

    @mixin icon($height, $width) {
        transition-duration: 0.5s;
        position: absolute;
        height: $height;
        width: $width;
        top: 30px;
        background-color: #212121;
    }

    @mixin icon-before($height, $width, $top) {
        transition-duration: 0.5s;
        position: absolute;
        width: $width;
        height: $height;
        background-color: #212121;
        content: '';
        top: $top;
    }

    @mixin icon-after($height, $width, $top) {
        transition-duration: 0.5s;
        position: absolute;
        width: $width;
        height: $height;
        background-color: #212121;
        content: '';
        top: $top;
    }

    .hamburger {
        @include btn();

        .icon {
            @include icon(4px, 30px);

            &:before {
                @include icon-before(4px, 30px, -10px);
            }

            &:after {
                @include icon-after(4px, 30px, 10px);
            }
        }

        &.open {
            .icon {
                transition-duration: 0.5s;
                background: transparent;

                &:before {
                    transform: rotateZ(45deg) scaleX(1.25)
                        translate(6.5px, 6.5px);
                }

                &:after {
                    transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -6px);
                }
            }
        }
        &:hover {
            cursor: pointer;
        }
    }
}
