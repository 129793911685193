.home {
    overflow-x: hidden;
}
.stage {
    position: relative;
    background: white;
    visibility: hidden;
}

.intro,
.footer {
    height: 100vh;
}

.intro {
    position: relative;
    padding: 0 5vw;
    background: rgb(162 189 191 / 51%);
    overflow: hidden;
}

.intro__content {
    position: absolute;
    right: 8%;
    bottom: 10%;
    z-index: 3;
    margin-left: 50px;

    @media all and (max-width: 768px) {
        right: auto;
    }
}

.intro__title {
    font-size: 5vw;
    overflow: hidden;
    letter-spacing: 0.7vw;
    padding-right: 2.3vw;
    z-index: 10;

    @media all and (max-width: 768px) {
        margin-bottom: 5vh;
    }
}

.intro__txt {
    margin-left: 20px;
    margin-top: 10px;
}

.intro__img {
    position: absolute;
    width: 100%;
    height: 85%;

    @media all and (max-width: 768px) {
        width: 75vw;
    }
}

.intro__img--1 {
    z-index: 2;
    left: 10%;
    bottom: 35%;

    @media all and (max-width: 768px) {
        // top: 15vh;
        left: 50%;
        bottom: 50vh;
    }
}

.slide {
    display: flex;
    align-items: stretch;
    height: 100vh;
    overflow: hidden;

    &:nth-of-type(even) {
        background: #c4cdc4;
    }

    @media all and (max-width: 768px) {
        display: block;
        position: relative;
    }
}

/*  Columns
========================================================================== */

.col {
    flex-basis: 50%;

    @media all and (max-width: 768px) {
        display: block;
        width: 100%;
        height: 100vh;
    }
}

.col--1 {
    position: relative;
    z-index: 1;

    @media all and (max-width: 768px) {
        position: relative;
        z-index: 1;
    }
}

.col--2 {
    position: relative;
    overflow: hidden;

    @media all and (max-width: 768px) {
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
    }
}

/*  ==========================================================================
    Column Content
    ========================================================================== */

.col__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    height: 100%;
    padding: 6vw 4vw 5vw;

    @media all and (max-width: 768px) {
        width: 80%;
    }
}

.col__content--1 {
    background: #d8c0c0;

    @media all and (max-width: 768px) {
        background: rgba(#d8c0c0, 0.9);
    }
}

.col__content--2 {
    background: #cdd5e0;

    @media all and (max-width: 768px) {
        background: rgba(#cdd5e0, 0.9);
    }
}

.col__content--3 {
    background: #f3d3b0;

    @media all and (max-width: 768px) {
        background: rgba(#f3d3b0, 0.9);
    }
}

.col__content--4 {
    background: #f8e9e6;

    @media all and (max-width: 768px) {
        background: rgba(#f8e9e6, 0.9);
    }
}

.col__content--5 {
    background: #d1e2ec;

    @media all and (max-width: 768px) {
        background: rgba(#d1e2ec, 0.9);
    }
}

.col__content--6 {
    background: #d7cec5;

    @media all and (max-width: 768px) {
        background: rgba(#d7cec5, 0.9);
    }
}

.col__content--7 {
    background: #c5d7c9;

    @media all and (max-width: 768px) {
        background: rgba(#c5d7c9, 0.9);
    }
}

/*  Column Content
    ========================================================================== */

.col__content-title {
    margin: 0 0 2vw;
    font-size: 6vw;
    letter-spacing: -0.3vw;

    @media all and (max-width: 768px) {
        margin: 0 0 6vw;
        font-size: 18vw;
    }

    @media all and (max-width: 480px) {
        font-size: 10vw;
    }
}

.col__content-wrap {
    display: flex;
    justify-content: flex-end;

    @media all and (max-width: 768px) {
        flex-direction: column;
    }
}

.col__content-txt {
    max-width: 80vw;
    order: 2;
    margin-left: -20px;

    @media all and (max-width: 768px) {
        order: 1;
        max-width: 40vw;
        margin: 0 0 10vw 0;
    }

    @media all and (max-width: 480px) {
        max-width: 100vw;
    }
}

.slide-link {
    position: relative;
    order: 1;
    display: flex;
    justify-content: flex-end;
    width: 75px;
    height: 53px;

    > * {
        pointer-events: none;
    }

    @media all and (max-width: 768px) {
        order: 2;
        align-self: flex-end;
    }
}

.slide-link__circ {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    border: 1px solid var(--dark);
}

.slide-link__line {
    position: absolute;
    top: 25px;
    left: 0;
    width: 64px;
    height: 3px;
    background: var(--dark);
}

.line {
    overflow: hidden;

    &:nth-of-type(even) {
        margin-top: -1vw;
    }
}

.line__inner {
    display: block;
}

.number {
    font-size: 40px;
    margin-left: 7px;
    letter-spacing: 0;
}

.slide__scroll-link {
    position: absolute;
    right: -113px;
    bottom: 3.5vw;
    display: block;
    width: 140px;
    height: 140px;
    background: var(--dark);
    overflow: hidden;

    @media all and (max-width: 768px) {
        display: none;
    }
}

.slide__scroll-line {
    position: absolute;
    left: 26px;
    bottom: 0;
    width: 1px;
    height: 100%;
}

.slide--0 {
    .slide__scroll-line {
        background: #c0d7d8;
    }
}

.slide--1 {
    .slide__scroll-line {
        background: #d8c0c0;
    }
}

.slide--2 {
    .slide__scroll-line {
        background: #cdd5e0;
    }
}

.slide--3 {
    .slide__scroll-line {
        background: #f3d3b0;
    }
}

.slide--4 {
    .slide__scroll-line {
        background: #f8e9e6;
    }
}

.slide--5 {
    .slide__scroll-line {
        background: #d1e2ec;
    }
}

.slide--6 {
    .slide__scroll-line {
        background: #d7cec5;
    }
}

/*  ==========================================================================
    Column Image
    ========================================================================== */

.col__image-wrap {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 160vh;
}

.img {
    object-fit: cover;
    width: 160%;
    height: 100%;
}

/*  ==========================================================================
    Footer
    ========================================================================== */

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #cecece;
}

.footer__link {
    font-size: 5vw;
    color: var(--dark);
    text-decoration: none;
    font-family: 'Cinzel', serif;
}

.footer__link-top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: var(--dark);
    font-size: 18px;
    color: black;
}

.footer__link-top-line {
    width: 1px;
    height: 50px;
    background: var(--dark);
}

.footer__copyright {
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);
}

.contact__photo {
    position: absolute;
    height: 8%;
    bottom: 0;
    left: -51px;
}

.brainAnimation {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 60px;
    gap: 10px;
}

// MOBILE

@media all and (max-width: 480px) {
    .slide {
        display: flex;
        overflow: visible;
        flex-direction: column;
        height: 100%;
    }

    .col {
        height: auto;
        overflow: hidden;
    }

    .col--2 {
        overflow: visible;
        position: unset;
    }

    .col__content {
        position: relative;
        display: block;
        overflow: visible;
        width: 100%;
    }

    .col__image-wrap {
        height: 100%;
        position: relative;
        transform: unset;
    }

    .img {
        width: 100%;
        height: auto;
    }

    .intro__title {
        padding: 0 2.3vw;
        font-size: 12vw;
    }

    .intro__img {
        transform: rotate(270deg);
        width: 90%;
        height: 100%;
    }

    .intro__content {
        right: 8%;
        top: 6%;
        margin-left: 8px;

        display: flex;
        align-items: end;
        height: 93vh;
    }

    .footer {
        justify-content: start;
    }

    .footer__content {
        margin-top: 30%;
        margin-left: 0;
    }
}
